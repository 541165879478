<template>
	<div>
		<van-nav-bar
			title="学生信息"
			left-text="返回"
			left-arrow
            fixed
			@click-left="onClickLeft"
		/>
        
        <div style="position: fixed;top: 45px;z-index: 2;width: 100%;">
            <van-notice-bar
                :left-icon="noticeBarIcon"
                :color="noticeBarColor"
                :scrollable="false"
                :text="status"
            >
                {{status}}
                <a v-if="form.level==1" href="/pay/qr-code" class="m-l-20" style="color: #ff6600;text-decoration: underline;">去支付学费</a>
            </van-notice-bar>
        </div>
        
        <div style="height: 90px;"></div>
		
		<van-form validate-first disabled>			
			<van-field
				v-model="form.name"
				label="姓名"
				name="name"
				trigger='onBlur'
				placeholder="学生姓名"
				required
			/>
			<van-field name="sex" label="性别" required>
				<template #input>
					<van-radio-group v-model="form.sex" direction="horizontal" disabled>
						<van-radio name="1">男</van-radio>
						<van-radio name="2">女</van-radio>
					</van-radio-group>
				</template>
			</van-field>
            
            <van-field
                v-model="form.minzu"
                label="民族"
                required
                name="minzu"
            />
			
			<van-field
				v-model="form.idCard"
				label="身份证号"
				required
				name="cardId"
				placeholder="身份证号"
				@blur="blurEvent('cardId')"
			/>
			
			<van-field required v-if="form.age" label="年龄" :value="form.age" readonly />
			
            <van-field
                v-model="form.liveAddress"
                label="现居住地址"
                required
                name="liveAddress"
                placeholder="现居住地址"
            />
			
			<van-field
				v-model="form.address"
				label="详细地址"
				required
				name="address"
				placeholder="详细地址"
			/>
            
            <van-field
                v-model="form.registerAddress"
                label="户籍地址"
                required
                name="registerAddress"
                placeholder="户籍地址"
            />
			
            <van-field
                v-model="form.registerInfo"
                label="详细地址"
                required
                name="registerInfo"
                placeholder="详细地址"
            />
            
            <van-field
                v-model="form.jiguan"
                label="籍贯"
                required
                name="jiguan"
                placeholder="籍贯"
            />
            
            <divider-10></divider-10>
            <van-divider>家属信息</van-divider>            
            <div v-for="(item,index) in form.jiashu" :key="index">
                <jiashu-guanxi :item="item" :index="index"></jiashu-guanxi>
            </div>
            
            <divider-10></divider-10>
            <van-divider>其他信息</van-divider>
            <van-field
                v-model="form.beizhu"
                rows="3"
                autosize
                label="备注"
                type="textarea"
                placeholder="请输入内容"
            />
            
			<!-- <div style="margin: 16px;">
				<van-button round block disabled type="primary" native-type="submit" @click="formEdit">修改信息</van-button>
			</div> -->
		</van-form>
	</div>
	
</template>

<script>
    import jiashuGuanxi from '../../components/form/jiashu-guanxi-disable.vue'
    
	export default {
        components:{
            jiashuGuanxi
        },
		data() {
			return {
                form:{}
			}
		},
        computed:{
            status(){
                switch (this.form.level) {
                    case 0 :
                        return '待审核'
                    case 1 :
                        return '审核通过'
                    case 2 :
                        return '审核未通过'
                    default :
                        return '未知状态'
                }
            },
            noticeBarColor(){
                switch (this.form.level) {
                    case 1 :
                        return '#07c160'
                    case 2 :
                        return '#ff000'
                    default :
                        return ''
                } 
            },
            noticeBarIcon(){
                switch (this.form.level) {
                    case 1 :
                        return 'passed'
                    case 2 :
                        return 'close'
                    default :
                        return 'info-o'
                } 
            }
        },
        mounted() {
            let data = this.$storage.getItem('studentSearch')
            if(!data){
                this.$router.go(-1);
                return false
            }
            data.chengji = JSON.parse(data.chengji)
            data.jiashu = JSON.parse(data.jiashu)
            data.sex = data.sex + ''
            this.form = data
        },
		methods: {
			formEdit(){
                this.$toast('暂时不可修改')
			},
			onClickLeft() {
				this.$router.go(-1);
			}
		},
        beforeDestroy() {
            this.$storage.clear('studentSearch')
        }
	}
</script>

<style>
</style>
