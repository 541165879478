<template>
    <div>
        <div v-if="index > 0">
            <van-divider dashed>家属 {{index}}</van-divider>
        </div>
        
        <van-field
            v-model="item.name"
            label="家属姓名"
            name="jiashu_name"
            required
            trigger='onBlur'
            placeholder="家属姓名"
        />
        
        <van-field
            v-model="item.guanxi"
            label="与家属关系"
            name="jiashu_guanxi"
            required
            trigger='onBlur'
            placeholder="家属姓名"
        />
        
        <van-field
            v-if="item.guanxi == '其他'"
            v-model="item.guanxi"
            label="与家属关系"
            required
            name="guanxi"
            placeholder="与家属关系"
        />
        
        <van-field
            v-model="item.tel"
            label="联系电话"
            name="phone"
            required
            trigger='onBlur'
            placeholder="联系电话"
        />
    </div>
</template>

<script>
    export default {
        props:{
            item:{
                type:Object
            },
            index:{
                type:Number,
                default:0
            }
        },
        data(){
            return {
               
            }
        },
        mounted() {
        },
        methods:{
            
        }
    }
</script>

<style>
</style>
